import { Component, OnInit } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { DomSanitizer } from "@angular/platform-browser"
import { LoginGraduatePilotoComponent } from "@puntaje/puntaje/new-modules/usuarios"

@Component({
    selector: "landing",
    templateUrl: "landing.component.html",
    styleUrls: ["landing.component.scss"]
})
export class LandingComponent implements OnInit {
    config: typeof config
    loginComponent = LoginGraduatePilotoComponent

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public authService: AuthService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        this.config = config
        if (this.authService.isLoggedIn()) {
            this.router.navigate(["home"])
        }
    }
}
