import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Injector, ChangeDetectorRef } from "@angular/core"
import { FormBuilder, FormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    Usuario,
    Usuarios,
    UsuariosCompletarRegistroForm,
    GrupoUsuarios,
    Lugar,
    Lugares,
    Establecimientos
} from "@puntaje/puntaje/api-services"
import { AuthService, GenericModalComponent, SessionService, BaseForm } from "@puntaje/shared/core"

import { BaseRegisterCompletarComponent } from "./base_register_completar.component"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { UsuariosCompletarRegistroPuntajeForm } from "@puntaje/puntaje/api-services"

@Component({
    selector: "usuario-completar-registro-graduatepolito",
    templateUrl: "register_completar_graduatepiloto.component.html",
    styleUrls: ["base_register_completar.component.scss"]
})
export class RegisterCompletarGraduatePilotoComponent extends BaseRegisterCompletarComponent {
    @ViewChild(GenericModalComponent) genericModal: GenericModalComponent
    openModal: EventEmitter<any> = new EventEmitter<any>()
    formType: typeof BaseForm = UsuariosCompletarRegistroPuntajeForm
    lugarColegio: Lugar
    filteredLugarColegio: Lugar[]
    identificadorUsuarioAlias: string

    constructor(
        usuariosService: Usuarios,
        authService: AuthService,
        sessionService: SessionService,
        router: Router,
        injector: Injector,
        grupoUsuariosService: GrupoUsuarios,
        cdr: ChangeDetectorRef,
        lugaresService: Lugares,
        establecimientosService: Establecimientos
    ) {
        super(
            usuariosService,
            authService,
            sessionService,
            router,
            injector,
            grupoUsuariosService,
            cdr,
            lugaresService,
            establecimientosService
        )

        this.formType = UsuariosCompletarRegistroPuntajeForm
        this.params = this.formType.formParams
        this.usuarioPaisParams = this.params["usuario_" + this.pais].class.formParams

        // if (!this.grupoUsuario || (this.grupoUsuario && !this.grupoUsuario.establecimiento)) {
        //     this.params.colegio.validations = null
        //     this.params.nivel_id.validations = null
        // }
    }

    checkTelefono() {
        const telefono = this.form.value["usuario_" + this.pais].telefono
        this.save()
    }

    goOmitir() {
        this.genericModal.close()
        this.save()
    }
}
