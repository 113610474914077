<div class="login-box">
    <ng-container *ngIf="!isLoggedIn()">
        <form novalidate [formGroup]="form" (ngSubmit)="login()">
            <div class="mensaje-error" *ngIf="invalidLogin">
                <div *ngIf="invalidLogin"><p [innerHTML]="invalidLoginMessage"></p></div>
            </div>
            <h2 class="login-title">{{ "landing.login.titulo_alumno" | t }}</h2>
            <div class="login-inputs">
                <div class="input-wrap">
                    <div class="form-group">
                        <fa class="icono" name="user"></fa>
                        <input
                            type="text"
                            name="email"
                            [(ngModel)]="email"
                            [placeholder]="placeholder"
                            class="form-control"
                            formControlName="email"
                        />
                        <div class="mensaje-error-input" *ngIf="checkEmail()">
                            <p>{{ "landing.login.error_user" | t }}</p>
                        </div>
                    </div>
                    <div class="form-group">
                        <fa class="icono" name="key"></fa>
                        <input
                            type="password"
                            name="password"
                            [(ngModel)]="password"
                            placeholder="{{ 'landing.login.pswd' | t }}"
                            *ngIf="!isLoggedIn()"
                            class="form-control"
                            formControlName="password"
                        />
                        <div class="mensaje-error-input" *ngIf="checkPassword()">
                            <p>{{ "landing.login.error_pswd" | t }}</p>
                        </div>
                    </div>
                </div>
                <cui-button type="submit" [isBlock]="true" size="large" class="btn-ingresar" *ngIf="!isLoggedIn()">
                    {{ "landing.login.btn_email" | t }}
                </cui-button>
                <google-button *ngIf="showLoginGoogle" class="btn-google"></google-button>
            </div>

            <div class="bottom-options">
                <div class="pwd-reset" *ngIf="enablePasswordRecovery">
                    <a [routerLink]="['/usuarios/passwords/forgot' || forgot_password_route]">
                        {{ "landing.login.btn_recover" | t }}
                    </a>
                </div>
                <div class="registro" *ngIf="enableRegister">
                    <cui-button-link
                        id="register-button"
                        [buttonType]="registerButtonType"
                        type="button"
                        class="btn_style"
                        (click)="openModal.emit(true)"
                    >
                        {{ "landing.login.btn_register" | t }}
                    </cui-button-link>
                </div>
                <generic-modal [buttonClicked]="openModal">
                    <modal-titulo>Registro Gradúate</modal-titulo>
                    <modal-contenido>
                        <div>
                            Las cuentas de Gradúate se crean en
                            <a href="https://eligemejor.sence.cl/">https://eligemejor.sence.cl/.</a>
                            Regístrate en este
                            <a href="https://eligetucurso.sence.cl/?campaign=graduate/">link</a>
                            y selecciona el curso "Gradúate".
                            <!-- Para saber cómo te puedes crear una cuenta a través de <a href="https://eligemejor.sence.cl/">https://eligemejor.sence.cl/,</a> haz clic <a style="cursor: pointer">aquí</a> -->
                        </div>
                    </modal-contenido>
                    <modal-botones>
                        <cui-button-link
                            class="btn_style"
                            href="https://eligetucurso.sence.cl/?campaign=graduate/"
                            style="margin-right: 5px"
                        >
                            Ir a Elige Mejor
                        </cui-button-link>
                        <cui-button-link class="btn_style" data-dismiss="modal" (click)="closeModal()">
                            Cancelar
                        </cui-button-link>
                    </modal-botones>
                </generic-modal>
            </div>
        </form>
    </ng-container>
    <ng-container *ngIf="isLoggedIn()">
        <div class="loggedIn-box">
            <div class="row">
                <div class="col-md-6">
                    <cui-button-link [isBlock]="true" class="btn_style btn-home" routerLink="/home">
                        <ogr-icon name="home-o" align="text-top" class="icono"></ogr-icon>
                        &nbsp;Home
                    </cui-button-link>
                </div>
                <div class="col-md-6">
                    <cui-button [isBlock]="true" buttonType="cancel" (click)="logout()" class="btn_style_2">
                        {{ "landing.login.btn_logout" | t }}
                    </cui-button>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<request-reset-password></request-reset-password>
